<template>
  <div>
    <head-layout
      :head-btn-options="headBtnOptions"
      :head-title="this.$route.query.type == 'edit' ? $t('cip.plat.app.aboutUs.title.editHeadTitle') : $t('cip.plat.app.aboutUs.title.addHeadTitle')"
      @head-save="headSave()"
      @head-save-cancel="headSave(true)"
      @head-cancel="headCancel"
    ></head-layout>

    <el-scrollbar>
      <form-layout
        ref="formLayout"
        :column="formColumn"
        :dataForm.sync="dataForm"
      ></form-layout>
    </el-scrollbar>
  </div>
</template>

<script>
import FormLayout from "@/views/components/layout/form-layout";
import HeadLayout from "@/views/components/layout/head-layout";
import ServerNameEnum from "@/util/ServerNameEnum";
import {add, getDetail} from "@/api/system/aboutUs";

export default {
  name: "mediumscreenAdd",
  components: {
    FormLayout,
    HeadLayout
  },
  data() {
    return {
      type: 'view',
      dataForm: {
        filePath: '',
      },
    }
  },
  computed: {
    headBtnOptions() {
      let result = [];
      if (['add', 'edit'].includes(this.type)) {
        result.push(
          {
            label: this.$t('cip.cmn.btn.saveBtn'),
            emit: "head-save",
            type: "button",
            icon: "",
            btnOptType: 'save',
          }
        );
        result.push(
          {
            label: this.$t('cip.cmn.btn.saveBackBtn'),
            emit: "head-save-cancel",
            type: "button",
            icon: "",
          }
        );
      }
      result.push(
        {
          label: this.$t('cip.cmn.btn.celBtn'),
          emit: "head-cancel",
          type: "button",
          icon: "",
          btnOptType: 'cancel',
        }
      );
      return result;
    },
    formColumn() {
      return [
        {
          label: this.$t("cip.plat.app.aboutUs.field.verCode"),
          prop: 'verCode',
          labelWidth: 130,
          span: 8,
          row: true,
          maxlength: 100,
          showWordLimit: true,
          readonly: !['add', 'edit'].includes(this.type),
          rules: [
            {
              required: true,
              message: this.$t('cip.cmn.rule.inputWarning') + this.$t("cip.plat.app.aboutUs.field.verCode"),
              trigger: "blur"
            },
            {
              pattern: /^[^\s]+(\s+[^\s]+)*$/,
              message: this.$t('cip.cmn.rule.deleteWarning'),
            }
          ],
        },
        {
          label: this.$t('cip.plat.app.aboutUs.field.urlPath'),
          prop: 'urlPath',
          type: 'upload',
          labelWidth: 130,
          dataType: 'string',
          loadText: this.$t('cip.plat.app.aboutUs.field.loadText'),
          propsHttp: {
            url: 'link',
            name: 'originalName',
            res: 'data'
          },
          limit: 1,
          accept: 'image/png, image/jpeg',
          tip: this.$t('cip.plat.app.aboutUs.field.image'),
          action: "/api/sinoma-resource/oss/endpoint/put-file-attach-name/platform",
          display: true,
          span: 15,
          readonly: !['edit', 'add'].includes(this.type),
          rules: [
            {
              required: true,
              message: this.$t('cip.cmn.rule.inputWarning') + this.$t("cip.plat.app.aboutUs.field.urlPath"),
              trigger: "blur"
            },
          ],

        },
        {
          label: this.$t('cip.plat.app.aboutUs.field.introduce'),
          prop: "introduce",
          type: 'content',
          labelWidth: 130,
          component: 'AvueUeditor',
          disabled: !['edit', 'add'].includes(this.type),
          options: {
            action: `${ServerNameEnum.SERVER_RESOURCE_}/oss/endpoint/put-file`,
            props: {
              res: "data",
              url: "link",
            }
          },
          minRows: 6,
        },
        {
          label: this.$t('cip.plat.sys.params.field.textarea'),
          prop: "textarea",
          type: 'content',
          labelWidth: 130,
          component: 'AvueUeditor',
          disabled: !['edit', 'add'].includes(this.type),
          options: {
            action: `${ServerNameEnum.SERVER_RESOURCE_}/oss/endpoint/put-file`,
            props: {
              res: "data",
              url: "link",
            }
          },
          minRows: 6,
        },
      ]
    }
  },
  created() {
    let {id, type} = this.$route.query;
    this.type = type;
    if (['view', 'edit'].includes(this.type)) {
      this.dataForm.id = id
      this.initData()
    }
  },
  mounted() {
  },
  methods: {
    htmlEncode(html) {
      //1.首先动态创建一个容器标签元素，如DIV
      let temp = document.createElement("div");
      //2.然后将要转换的字符串设置为这个元素的innerText或者textContent
      (temp.textContent != undefined) ? (temp.textContent = html) : (temp.innerText = html);
      //3.最后返回这个元素的innerHTML，即得到经过HTML编码转换的字符串了
      let output = temp.innerHTML;
      temp = null;
      return output;
    },
    htmlDecode(text) {
      //1.首先动态创建一个容器标签元素，如DIV
      let temp = document.createElement("div");
      //2.然后将要转换的字符串设置为这个元素的innerHTML(ie，火狐，google都支持)
      temp.innerHTML = text;
      //3.最后返回这个元素的innerText或者textContent，即得到经过HTML解码的字符串了。
      let output = temp.innerText || temp.textContent;
      temp = null;
      return output;
    },
    initData() {
      console.log(this.dataForm.id)
      getDetail(this.dataForm.id).then(res => {
        // res.data.data.introduce = this.htmlDecode(res.data.data.introduce)
        const {data} = res.data;
        this.dataForm = data;
        this.dataForm.textarea = JSON.parse(data.introduce).en;
        this.dataForm.introduce = JSON.parse(data.introduce).zh;
      })

    },

    headSave(cancel = false) {
      this.$refs.formLayout.$refs.form.validate(async (valid) => {
        if (valid) {
          this.$loading()
          let a = this.htmlEncode(this.$refs.formLayout.dataForm.introduce)
          let b = this.htmlEncode(this.$refs.formLayout.dataForm.textarea)
          this.$refs.formLayout.dataForm.introduce = {
            "zh": a,
            "en": b
          }
          this.$refs.formLayout.dataForm.introduce = JSON.stringify(this.$refs.formLayout.dataForm.introduce)


          add({
            ...this.$refs.formLayout.dataForm,
          })
            .then(res => {
              const {msg, data} = res.data;
              if (res.data.code == 200) {
                this.$message.success(this.$t('cip.cmn.msg.success.operateSuccess'))
              } else {
                this.$message.success(msg);
              }

              if (cancel) {
                this.$router.$avueRouter.closeTag();
                this.$router.back();
              } else {
                //刷新数据
                Object.assign(this.dataForm, data)
                this.dataForm = data;
                this.initData();
                this.type = 'edit';
              }
            })
            .finally(() => {
              this.$loading().close()
              this.$refs.formLayout.$refs.form.allDisabled = false;
            })
        }
      })
    },
    headCancel() {
      this.$router.$avueRouter.closeTag();
      this.$router.back();
    },
  }
}
</script>

<style scoped>

</style>
